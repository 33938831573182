import { Fragment, useEffect, useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Navigate, useNavigate } from "react-router-dom";
import LoadingScreen from "../../../../components/LoadingScreen";
import qs from "qs";
import APIEndPoint from "../../../../utils/APIEndPoint";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Admins({ user: theCurrentUser, me } = {}) {
  const navigate = useNavigate();
  console.log(`Admins theCurrentUser`, theCurrentUser);
  console.log("Admins me", me);
  const possibleUserSearchVectors = ["Email", "First Name", "Surname"];
  const [open, setOpen] = useState(false);
  const [showUserSearchResult, setShowUserSearchResult] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchBy, setSearchBy] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [adminToDemote, setAdminToDemote] = useState(null);
  const [demoteAdminLoading, setDemoteAdminLoading] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userSearchResult, setUserSearchResult] = useState([]);
  const [powerLevel, setPowerLevel] = useState(
    !!me &&
      !!me.admin &&
      Number(me.admin.status) === 1 &&
      !isNaN(me.admin.powerLevel)
      ? Number(me.admin.powerLevel)
      : 1
  );
  useEffect(() => {
    const getData = setTimeout(() => {
      if (!!me && !!me.admin && Number(me.admin.status) === 1 && !loaded) {
        theCurrentUser.getSession((error, cognitoUserSession) => {
          console.log(`error`, error);
          const theAccessToken = cognitoUserSession.getAccessToken();
          // console.log(`theAccessToken.getJwtToken()`, theAccessToken.getJwtToken());
          console.log(
            `It is ${
              theAccessToken.getExpiration() > Date.now() / 1000
            } that session is still active for ${
              theAccessToken.getExpiration() - Date.now() / 1000
            } seconds`
          );
          var url = `${APIEndPoint}/api/admin/all`;
          // var bearer = "Bearer " + bearer_token;
          fetch(url, {
            method: "GET",
            // withCredentials: true,
            // credentials: "include",
            headers: {
              Authorization: theAccessToken.getJwtToken(),
              // "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
              // "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then(({ success, users: gottenUsers }) => {
              if (!!success && Array.isArray(gottenUsers)) {
                setUsers(gottenUsers);
              }
            })
            .catch((error) => {
              console.log(`error`, error);
            })
            .then(() => {
              setLoaded(true);
            });
        });
      }
    }, 500);
    return () => clearTimeout(getData);
  }, [loaded, users]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (
        !!me &&
        !!me.admin &&
        Number(me.admin.status) === 1 &&
        !!searchTerm &&
        typeof searchTerm === "string" &&
        searchTerm.length > 1
      ) {
        theCurrentUser.getSession((error, cognitoUserSession) => {
          console.log(`error`, error);
          const theAccessToken = cognitoUserSession.getAccessToken();
          // console.log(`theAccessToken.getJwtToken()`, theAccessToken.getJwtToken());
          console.log(
            `It is ${
              theAccessToken.getExpiration() > Date.now() / 1000
            } that session is still active for ${
              theAccessToken.getExpiration() - Date.now() / 1000
            } seconds`
          );
          var url = `${APIEndPoint}/api/user/search/by/${searchBy}?${qs.stringify(
            { value: searchTerm }
          )}`;
          // var bearer = "Bearer " + bearer_token;
          fetch(url, {
            method: "GET",
            // withCredentials: true,
            // credentials: "include",
            headers: {
              Authorization: theAccessToken.getJwtToken(),
              // "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
              // "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then(({ success, users: gottenUsers }) => {
              if (!!success && Array.isArray(gottenUsers)) {
                setUserSearchResult(gottenUsers);
              }
            })
            .catch((error) => {
              console.log(`error`, error);
            })
            .then(() => {
              setLoaded(true);
            });
        });
      }
    }, 500);
    return () => clearTimeout(getData);
  }, [searchTerm]);
  console.log(`adminToDemote`, adminToDemote);
  return !!me && !!me.admin && Number(me.admin.status) === 1 ? (
    <div className="divide-y divide-gray-200 lg:col-span-9 py-6 px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Admins</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their name, title,
            email and role.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => setOpen(true)}
          >
            Add admin
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {users.map((person) => (
                    <tr key={person.emails[0].value}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {person.name.first}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.name.title}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.emails[0].value}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <a
                          href="/"
                          onClick={(event) => {
                            event.preventDefault();
                            setAdminToDemote(person);
                          }}
                          className="text-indigo-600 hover:text-indigo-900 mr-3"
                        >
                          Demote
                          <span className="sr-only">, {person.name.first}</span>
                        </a>
                        <a
                          href="/"
                          onClick={(event) => {
                            event.preventDefault();
                            navigate(`/user/${person.id}`);
                          }}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Details
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {!loaded ? (
                <LoadingScreen
                  loading="Fetching Admin users"
                  message="Please wait, Admin users are being fetched."
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {open ? (
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                      <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                New Admin
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                  onClick={() => setOpen(false)}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="mt-1">
                              <p className="text-sm text-indigo-300">
                                Elevate a user to admin of this platform.
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="px-4 sm:px-6">
                              <div className="space-y-6 pt-6 pb-5">
                                {!selectedUser ? (
                                  <div>
                                    <div className="mt-1">
                                      <Listbox
                                        value={searchBy}
                                        onChange={(value) => {
                                          setSearchTerm("");
                                          setSearchBy(value);
                                        }}
                                      >
                                        {({ open }) => (
                                          <>
                                            <Listbox.Label className="block text-sm font-medium text-gray-700">
                                              Search By
                                            </Listbox.Label>
                                            <div className="relative mt-1">
                                              <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                                <span className="block truncate">
                                                  {
                                                    possibleUserSearchVectors[
                                                      searchBy
                                                    ]
                                                  }
                                                </span>
                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                  <ChevronUpDownIcon
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              </Listbox.Button>

                                              <Transition
                                                show={open}
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                              >
                                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                  {possibleUserSearchVectors.map(
                                                    (person, key) => (
                                                      <Listbox.Option
                                                        key={key}
                                                        className={({
                                                          active,
                                                        }) =>
                                                          classNames(
                                                            active
                                                              ? "text-white bg-indigo-600"
                                                              : "text-gray-900",
                                                            "relative cursor-default select-none py-2 pl-3 pr-9"
                                                          )
                                                        }
                                                        value={key}
                                                      >
                                                        {({
                                                          selected,
                                                          active,
                                                        }) => (
                                                          <>
                                                            <span
                                                              className={classNames(
                                                                selected
                                                                  ? "font-semibold"
                                                                  : "font-normal",
                                                                "block truncate"
                                                              )}
                                                            >
                                                              {person}
                                                            </span>

                                                            {selected ? (
                                                              <span
                                                                className={classNames(
                                                                  active
                                                                    ? "text-white"
                                                                    : "text-indigo-600",
                                                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                                                )}
                                                              >
                                                                <CheckIcon
                                                                  className="h-5 w-5"
                                                                  aria-hidden="true"
                                                                />
                                                              </span>
                                                            ) : null}
                                                          </>
                                                        )}
                                                      </Listbox.Option>
                                                    )
                                                  )}
                                                </Listbox.Options>
                                              </Transition>
                                            </div>
                                          </>
                                        )}
                                      </Listbox>
                                      {/* <select
                                    value={searchBy}
                                    id="new-admin-search-by-input"
                                    name="new-admin-search-by-input"
                                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    // defaultValue={0}
                                  >
                                    {possibleUserSearchVectors.map(
                                      (value, key) => {
                                        return (
                                          <option value={key} key={key}>
                                            {value}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select> */}
                                      {/* <input
                                    value={searchTerm}
                                    onChange={(event) => {
                                      event.preventDefault();
                                      setSearchTerm(event.target.value);
                                    }}
                                    type="text"
                                    name="new-admin-info-input"
                                    id="new-admin-info-input"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  /> */}
                                    </div>
                                  </div>
                                ) : null}

                                {!!selectedUser ? (
                                  <div
                                    className={
                                      "text-white bg-indigo-600 relative cursor-default select-none py-2 pl-3 pr-9"
                                    }
                                  >
                                    <small>Upgrade to Admin</small>
                                    <span
                                      className={"font-semibold block truncate"}
                                    >
                                      {`${selectedUser.name.title} ${selectedUser.name.first} ${selectedUser.name.last}`}
                                    </span>
                                    <span
                                      onClick={(event) => {
                                        event.preventDefault();
                                        setSelectedUser(null);
                                      }}
                                      className={
                                        "text-white absolute inset-y-0 right-0 flex items-center pr-4"
                                      }
                                    >
                                      <XMarkIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </div>
                                ) : (
                                  <div>
                                    <div className="mt-1">
                                      <label
                                        htmlFor="new-admin-search-term-input"
                                        className="block text-sm font-medium text-gray-900"
                                      >
                                        Search a regular User
                                      </label>
                                      <div className="relative mt-1">
                                        <input
                                          value={searchTerm}
                                          onChange={(event) => {
                                            event.preventDefault();
                                            setSearchTerm(event.target.value);
                                          }}
                                          onFocus={(event) => {
                                            event.preventDefault();
                                            setShowUserSearchResult(true);
                                          }}
                                          onBlur={(event) => {
                                            event.preventDefault();
                                            // setShowUserSearchResult(false);
                                          }}
                                          type="text"
                                          placeholder={`By ${possibleUserSearchVectors[searchBy]}`}
                                          name="new-admin-search-term-input"
                                          id="new-admin-search-term-input"
                                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        />
                                        {!!showUserSearchResult ? (
                                          <ul className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {userSearchResult.map(
                                              (person, key) => {
                                                return !!person.id &&
                                                  !!Number(!!person.status) &&
                                                  !!person.name &&
                                                  !!person.name.first ? (
                                                  <li
                                                    key={key}
                                                    className={
                                                      "hover:bg-indigo-600 text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9"
                                                    }
                                                    onClick={(event) => {
                                                      event.preventDefault();
                                                      setSelectedUser(person);
                                                      setShowUserSearchResult(
                                                        false
                                                      );
                                                    }}
                                                  >
                                                    <span
                                                      className={
                                                        "font-normal block truncate"
                                                      }
                                                    >
                                                      {`${person.name.title} ${person.name.first} ${person.name.last}`}
                                                    </span>
                                                  </li>
                                                ) : null;
                                              }
                                            )}
                                          </ul>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            onClick={(event) => {
                              event.preventDefault();
                              if (!selectedUser) {
                                return alert(`No user selected yet`);
                              }
                              theCurrentUser.getSession(
                                (error, cognitoUserSession) => {
                                  console.log(`error`, error);
                                  const theAccessToken =
                                    cognitoUserSession.getAccessToken();
                                  // console.log(`theAccessToken.getJwtToken()`, theAccessToken.getJwtToken());
                                  console.log(
                                    `It is ${
                                      theAccessToken.getExpiration() >
                                      Date.now() / 1000
                                    } that session is still active for ${
                                      theAccessToken.getExpiration() -
                                      Date.now() / 1000
                                    } seconds`
                                  );

                                  // setNewUserTitle
                                  fetch(
                                    `${APIEndPoint}/api/user/${selectedUser.id}/admin`,
                                    {
                                      method: "PUT",
                                      body: JSON.stringify({
                                        decision: true,
                                        powerLevel: powerLevel + 1,
                                      }),
                                      // withCredentials: true,
                                      // credentials: "include",
                                      headers: {
                                        Authorization:
                                          theAccessToken.getJwtToken(),
                                        // "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((response) => response.json())
                                    .then(
                                      ({
                                        success,
                                        user,
                                        message = `An unexpected error occured`,
                                      }) => {
                                        if (!!success && Array.isArray(users)) {
                                          let replaceIndex;
                                          users.find((currentUser, index) => {
                                            if (user.id === currentUser.id) {
                                              replaceIndex = index;
                                              return true;
                                            }
                                            return false;
                                          });
                                          let newUsers = [];
                                          if (isNaN(replaceIndex)) {
                                            newUsers = [user, ...users];
                                          } else {
                                            newUsers = [...users];
                                            newUsers[Number(replaceIndex)] =
                                              user;
                                          }
                                          setUsers(newUsers);
                                        }
                                        alert(message);
                                      }
                                    )
                                    .catch((error) => {
                                      console.log(`error`, error);
                                      alert(error.message);
                                    })
                                    .then(() => {
                                      setLoaded(true);
                                    });
                                }
                              );
                            }}
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            Elevate to Admin
                          </button>
                        </div>
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : null}
      {!!demoteAdminLoading ? (
        <LoadingScreen
          loading={`Demoting ${adminToDemote.name.title} ${adminToDemote.name.first} ${adminToDemote.name.last}`}
          message="Please wait, We are awaiting demotion confirmation."
          zIndex={11}
        />
      ) : null}
      {!!adminToDemote ? (
        <Transition.Root show={!!adminToDemote} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                        <CheckIcon
                          className="h-6 w-6 text-green-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          {`Demote ${adminToDemote.name.title} ${adminToDemote.name.first} ${adminToDemote.name.last}`}
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {`Are you sure you want to demote ${adminToDemote.name.title} ${adminToDemote.name.first} ${adminToDemote.name.last} from admin priviledges`}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                        onClick={(event) => {
                          event.preventDefault();
                          setDemoteAdminLoading(true);

                          if (!adminToDemote) {
                            setDemoteAdminLoading(false);
                            return alert(`No admin selected yet`);
                          }
                          theCurrentUser.getSession(
                            (error, cognitoUserSession) => {
                              console.log(`error`, error);
                              if (!!error) {
                                setDemoteAdminLoading(false);
                                return alert(error.message);
                              }
                              const theAccessToken =
                                cognitoUserSession.getAccessToken();
                              // console.log(`theAccessToken.getJwtToken()`, theAccessToken.getJwtToken());
                              console.log(
                                `It is ${
                                  theAccessToken.getExpiration() >
                                  Date.now() / 1000
                                } that session is still active for ${
                                  theAccessToken.getExpiration() -
                                  Date.now() / 1000
                                } seconds`
                              );

                              // setNewUserTitle
                              fetch(
                                `${APIEndPoint}/api/user/${adminToDemote.id}/admin`,
                                {
                                  method: "PUT",
                                  body: JSON.stringify({
                                    decision: false,
                                  }),
                                  // withCredentials: true,
                                  // credentials: "include",
                                  headers: {
                                    Authorization: theAccessToken.getJwtToken(),
                                    // "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
                                    "Content-Type": "application/json",
                                  },
                                }
                              )
                                .then((response) => response.json())
                                .then(
                                  ({
                                    success,
                                    user,
                                    message = `An unexpected error occured`,
                                  }) => {
                                    if (!!success && Array.isArray(users)) {
                                      setUsers(
                                        users.filter((currentUser) => {
                                          return !(user.id === currentUser.id);
                                        })
                                      );
                                    }
                                    alert(message);
                                  }
                                )
                                .catch((error) => {
                                  console.log(`error`, error);
                                  alert(error.message);
                                })
                                .then(() => {
                                  setAdminToDemote(null);
                                  setDemoteAdminLoading(false);
                                });
                            }
                          );
                        }}
                      >
                        Demote
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                        onClick={(event) => {
                          event.preventDefault();
                          setAdminToDemote(null);
                          setDemoteAdminLoading(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : null}
    </div>
  ) : (
    <Navigate to="/" replace={true} />
  );
}
