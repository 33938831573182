import { Fragment, useEffect, useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import qs from "qs";
import {
  LinkIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import LoadingScreen from "../../../../components/LoadingScreen";
import APIEndPoint from "../../../../utils/APIEndPoint";
import { useNavigate } from "react-router-dom";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Groups({
  groupTypes,
  setGroupTypes,
  user: theCurrentUser,
  me,
} = {}) {
  const navigate = useNavigate();
  const groupTypesKeys = !!groupTypes ? Object.keys(groupTypes) : [];
  const [loaded, setLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [[ownOrganizationGroups, membershipGroups, allGroups], setGroups] =
    useState([[], [], []]);
  const [newGroupType, setNewGroupType] = useState(0);
  const [searchBy, setSearchBy] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [groupName, setGroupName] = useState("");
  const [organizationSearchResult, setOrganizationSearchResult] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [showOrganizationSearchResult, setShowOrganizationSearchResult] =
    useState(false);
  const theTabs = ["Own Organization", "Membership", "All"];
  const tabExplanations = [
    "Groups from Organizations you own",
    "Groups you are member of",
    "Other Groups on the System",
  ];
  const possibleOrganizationSearchVectors = [
    "Name",
    "Owner's First Name",
    "Owner's Email",
    "Owner's Surname",
    "Owner's Title",
  ];
  const [groupCreationLoading, setGroupCreationLoading] = useState("");

  useEffect(() => {
    const getData = setTimeout(() => {
      if (
        !!me &&
        // && !!me.admin && Number(me.admin.status) === 1
        !loaded
      ) {
        theCurrentUser.getSession((error, cognitoUserSession) => {
          console.log(`error`, error);
          const theAccessToken = cognitoUserSession.getAccessToken();
          // console.log(`theAccessToken.getJwtToken()`, theAccessToken.getJwtToken());
          console.log(
            `It is ${
              theAccessToken.getExpiration() > Date.now() / 1000
            } that session is still active for ${
              theAccessToken.getExpiration() - Date.now() / 1000
            } seconds`
          );
          var url = `${APIEndPoint}/api/group/${activeTab || 0}`;
          // var bearer = "Bearer " + bearer_token;
          fetch(url, {
            method: "GET",
            // withCredentials: true,
            // credentials: "include",
            headers: {
              Authorization: theAccessToken.getJwtToken(),
              // "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
              // "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then(({ success, result: gottenResult, groupType }) => {
              if (!!success && Array.isArray(gottenResult)) {
                setGroups([
                  activeTab === 0 ? gottenResult : ownOrganizationGroups,
                  activeTab === 1 ? gottenResult : membershipGroups,
                  activeTab === 2 ? gottenResult : allGroups,
                ]);
              }
              if (!!groupType) {
                setGroupTypes(groupType);
              }
            })
            .catch((error) => {
              console.log(`error`, error);
            })
            .then(() => {
              setLoaded(true);
            });
        });
      }
    }, 500);
    return () => clearTimeout(getData);
  }, [loaded, ownOrganizationGroups, membershipGroups, allGroups]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (
        !!me &&
        // !!me.admin &&
        // Number(me.admin.status) === 1 &&
        !!searchTerm &&
        typeof searchTerm === "string" &&
        searchTerm.length > 1
      ) {
        theCurrentUser.getSession((error, cognitoUserSession) => {
          console.log(`error`, error);
          const theAccessToken = cognitoUserSession.getAccessToken();
          // console.log(`theAccessToken.getJwtToken()`, theAccessToken.getJwtToken());
          console.log(
            `It is ${
              theAccessToken.getExpiration() > Date.now() / 1000
            } that session is still active for ${
              theAccessToken.getExpiration() - Date.now() / 1000
            } seconds`
          );
          var url = `${APIEndPoint}/api/field/1/vector/${searchBy}?${qs.stringify(
            { value: searchTerm }
          )}`;
          // var bearer = "Bearer " + bearer_token;
          fetch(url, {
            method: "GET",
            // withCredentials: true,
            // credentials: "include",
            headers: {
              Authorization: theAccessToken.getJwtToken(),
              // "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
              // "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then(({ success, result: gottenOrganizations }) => {
              if (!!success && Array.isArray(gottenOrganizations)) {
                setOrganizationSearchResult(gottenOrganizations);
              }
            })
            .catch((error) => {
              console.log(`error`, error);
            });
        });
      }
    }, 500);
    return () => clearTimeout(getData);
  }, [searchTerm]);
  return (
    <div className="divide-y divide-gray-200 lg:col-span-9 py-6 px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Groups</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the allGroups associated your account including their
            name, title, email and role.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => setOpen(true)}
          >
            Add new
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="sm:mt-2 2xl:mt-5">
          <div className="border-b border-gray-200">
            <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {theTabs.map((name, key) => {
                  const isActive = activeTab === key;
                  return (
                    <a
                      key={key}
                      href="/"
                      onClick={(event) => {
                        event.preventDefault();
                        setActiveTab(key);
                        setLoaded(false);
                      }}
                      className={classNames(
                        isActive
                          ? "border-pink-500 text-gray-900"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                        "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                      )}
                      aria-current={isActive ? "page" : undefined}
                    >
                      {name}
                    </a>
                  );
                })}
              </nav>
            </div>
          </div>
        </div>
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {
                [
                  <table
                    key={1}
                    className="min-w-full divide-y divide-gray-300"
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Group Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Group Organization Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {ownOrganizationGroups.map((currentGroup) => (
                        <tr key={currentGroup.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {currentGroup.name}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {currentGroup.organizationName}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <a
                              href="/"
                              onClick={(event) => {
                                event.preventDefault();
                                navigate(`/group/${currentGroup.id}`);
                              }}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              Details
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>,
                  <table
                    key={2}
                    className="min-w-full divide-y divide-gray-300"
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Group Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Group Organization Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {membershipGroups.map((currentMembershipGroup) => (
                        <tr key={currentMembershipGroup.group}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {currentMembershipGroup.groupName}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {currentMembershipGroup.groupOrganizationName}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <a
                              href="/"
                              onClick={(event) => {
                                event.preventDefault();
                                navigate(`/group/${currentMembershipGroup.group}`);
                              }}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              Details
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>,
                  <table
                    key={3}
                    className="min-w-full divide-y divide-gray-300"
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Group Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Group Organization Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {allGroups.map((currentGroup) => (
                        <tr key={currentGroup.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {currentGroup.name}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {currentGroup.organizationName}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <a
                              href="/"
                              onClick={(event) => {
                                event.preventDefault();
                                navigate(`/group/${currentGroup.id}`);
                              }}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              Details
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>,
                ][Number(activeTab)]
              }
              {!loaded ? (
                <LoadingScreen
                  loading="Fetching Groups"
                  message="Please wait, Groups are being fetched."
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              New Group
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">
                              Create a group on this platform.
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              {!!selectedOrganization ? (
                                <div
                                  className={
                                    "text-white bg-indigo-600 relative cursor-default select-none py-2 pl-3 pr-9"
                                  }
                                >
                                  <small>Create Group For</small>
                                  <span
                                    className={"font-semibold block truncate"}
                                  >
                                    {`${selectedOrganization.name}`}
                                  </span>
                                  <span
                                    onClick={(event) => {
                                      event.preventDefault();
                                      setSelectedOrganization(null);
                                    }}
                                    className={
                                      "text-white absolute inset-y-0 right-0 flex items-center pr-4"
                                    }
                                  >
                                    <XMarkIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </div>
                              ) : (
                                <div>
                                  {!selectedOrganization ? (
                                    <div>
                                      <div className="mt-1">
                                        <Listbox
                                          value={searchBy}
                                          onChange={(value) => {
                                            setSearchTerm("");
                                            setSearchBy(value);
                                          }}
                                        >
                                          {({ open }) => (
                                            <>
                                              <Listbox.Label className="block text-sm font-medium text-gray-700">
                                                Search By
                                              </Listbox.Label>
                                              <div className="relative mt-1">
                                                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                                  <span className="block truncate">
                                                    {
                                                      possibleOrganizationSearchVectors[
                                                        searchBy
                                                      ]
                                                    }
                                                  </span>
                                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <ChevronUpDownIcon
                                                      className="h-5 w-5 text-gray-400"
                                                      aria-hidden="true"
                                                    />
                                                  </span>
                                                </Listbox.Button>

                                                <Transition
                                                  show={open}
                                                  as={Fragment}
                                                  leave="transition ease-in duration-100"
                                                  leaveFrom="opacity-100"
                                                  leaveTo="opacity-0"
                                                >
                                                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {possibleOrganizationSearchVectors.map(
                                                      (person, key) => (
                                                        <Listbox.Option
                                                          key={key}
                                                          className={({
                                                            active,
                                                          }) =>
                                                            classNames(
                                                              active
                                                                ? "text-white bg-indigo-600"
                                                                : "text-gray-900",
                                                              "relative cursor-default select-none py-2 pl-3 pr-9"
                                                            )
                                                          }
                                                          value={key}
                                                        >
                                                          {({
                                                            selected,
                                                            active,
                                                          }) => (
                                                            <>
                                                              <span
                                                                className={classNames(
                                                                  selected
                                                                    ? "font-semibold"
                                                                    : "font-normal",
                                                                  "block truncate"
                                                                )}
                                                              >
                                                                {person}
                                                              </span>

                                                              {selected ? (
                                                                <span
                                                                  className={classNames(
                                                                    active
                                                                      ? "text-white"
                                                                      : "text-indigo-600",
                                                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                                                  )}
                                                                >
                                                                  <CheckIcon
                                                                    className="h-5 w-5"
                                                                    aria-hidden="true"
                                                                  />
                                                                </span>
                                                              ) : null}
                                                            </>
                                                          )}
                                                        </Listbox.Option>
                                                      )
                                                    )}
                                                  </Listbox.Options>
                                                </Transition>
                                              </div>
                                            </>
                                          )}
                                        </Listbox>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="mt-1">
                                    <label
                                      htmlFor="new-admin-search-term-input"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Search an Organization
                                    </label>
                                    <div className="relative mt-1">
                                      <input
                                        value={searchTerm}
                                        onChange={(event) => {
                                          event.preventDefault();
                                          setSearchTerm(event.target.value);
                                        }}
                                        onFocus={(event) => {
                                          event.preventDefault();
                                          setShowOrganizationSearchResult(true);
                                        }}
                                        onBlur={(event) => {
                                          event.preventDefault();
                                          // setShowOrganizationSearchResult(false);
                                        }}
                                        type="text"
                                        placeholder={`By ${possibleOrganizationSearchVectors[searchBy]}`}
                                        name="new-admin-search-term-input"
                                        id="new-admin-search-term-input"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      />
                                      {!!showOrganizationSearchResult ? (
                                        <ul className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                          {organizationSearchResult.map(
                                            (currentOrganization, key) => {
                                              return !!currentOrganization.id &&
                                                !!currentOrganization.name ? (
                                                <li
                                                  key={key}
                                                  className={
                                                    "hover:bg-indigo-600 text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9"
                                                  }
                                                  onClick={(event) => {
                                                    event.preventDefault();
                                                    setSelectedOrganization(
                                                      currentOrganization
                                                    );
                                                    setShowOrganizationSearchResult(
                                                      false
                                                    );
                                                  }}
                                                >
                                                  <span
                                                    className={
                                                      "font-normal block truncate"
                                                    }
                                                  >
                                                    {`${currentOrganization.name}`}
                                                  </span>
                                                </li>
                                              ) : null;
                                            }
                                          )}
                                        </ul>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div>
                                <div className="mt-1">
                                  <Listbox
                                    value={searchBy}
                                    onChange={(value) => {
                                      setNewGroupType(value);
                                    }}
                                  >
                                    {({ open }) => (
                                      <>
                                        <Listbox.Label className="block text-sm font-medium text-gray-700">
                                          Group Type
                                        </Listbox.Label>
                                        <div className="relative mt-1">
                                          <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                            <span className="block truncate">
                                              {groupTypes[newGroupType]}
                                            </span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                              <ChevronUpDownIcon
                                                className="h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          </Listbox.Button>

                                          <Transition
                                            show={open}
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                          >
                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                              {groupTypesKeys.map(
                                                (currentGroupTypeKey) => (
                                                  <Listbox.Option
                                                    key={currentGroupTypeKey}
                                                    className={({ active }) =>
                                                      classNames(
                                                        active
                                                          ? "text-white bg-indigo-600"
                                                          : "text-gray-900",
                                                        "relative cursor-default select-none py-2 pl-3 pr-9"
                                                      )
                                                    }
                                                    value={currentGroupTypeKey}
                                                  >
                                                    {({ selected, active }) => (
                                                      <>
                                                        <span
                                                          className={classNames(
                                                            selected
                                                              ? "font-semibold"
                                                              : "font-normal",
                                                            "block truncate"
                                                          )}
                                                        >
                                                          {
                                                            groupTypes[
                                                              currentGroupTypeKey
                                                            ]
                                                          }
                                                        </span>

                                                        {selected ? (
                                                          <span
                                                            className={classNames(
                                                              active
                                                                ? "text-white"
                                                                : "text-indigo-600",
                                                              "absolute inset-y-0 right-0 flex items-center pr-4"
                                                            )}
                                                          >
                                                            <CheckIcon
                                                              className="h-5 w-5"
                                                              aria-hidden="true"
                                                            />
                                                          </span>
                                                        ) : null}
                                                      </>
                                                    )}
                                                  </Listbox.Option>
                                                )
                                              )}
                                            </Listbox.Options>
                                          </Transition>
                                        </div>
                                      </>
                                    )}
                                  </Listbox>
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="group-name-input"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Group Name
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    value={groupName}
                                    onChange={(event) => {
                                      event.preventDefault();
                                      setGroupName(event.target.value);
                                    }}
                                    name="group-name-input"
                                    id="group-name-input"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          onClick={(event) => {
                            event.preventDefault();
                            setGroupCreationLoading(true);
                            if (
                              groupName === undefined ||
                              groupName === null ||
                              (isNaN(groupName) &&
                                !(typeof groupName.trim === "function")) ||
                              `${groupName}`.trim().length < 4
                            ) {
                              setGroupCreationLoading(false);
                              return alert(
                                `Group name must contain at least four(4) characters`
                              );
                            }
                            if (!selectedOrganization) {
                              setGroupCreationLoading(false);
                              return alert(
                                `Please provide a organization before proceeding to create group`
                              );
                            }
                            theCurrentUser.getSession(
                              (error, cognitoUserSession) => {
                                console.log(`error`, error);
                                if (!!error) {
                                  setGroupCreationLoading(false);
                                  return alert(error.message);
                                }
                                const theAccessToken =
                                  cognitoUserSession.getAccessToken();
                                // console.log(`theAccessToken.getJwtToken()`, theAccessToken.getJwtToken());
                                console.log(
                                  `It is ${
                                    theAccessToken.getExpiration() >
                                    Date.now() / 1000
                                  } that session is still active for ${
                                    theAccessToken.getExpiration() -
                                    Date.now() / 1000
                                  } seconds`
                                );

                                fetch(
                                  `${APIEndPoint}/api/organization/${selectedOrganization.id}/group`,
                                  {
                                    method: "POST",
                                    body: JSON.stringify({
                                      name: groupName,
                                      type: newGroupType,
                                    }),
                                    // withCredentials: true,
                                    // credentials: "include",
                                    headers: {
                                      Authorization:
                                        theAccessToken.getJwtToken(),
                                      // "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
                                      "Content-Type": "application/json",
                                    },
                                  }
                                )
                                  .then((response) => response.json())
                                  .then(
                                    ({
                                      success,
                                      group,
                                      message = `An unexpected error occured`,
                                    }) => {
                                      if (
                                        !!success &&
                                        Array.isArray(allGroups)
                                      ) {
                                        setGroups([group, ...allGroups]);
                                        setOpen(false);
                                      }
                                      alert(message);
                                    }
                                  )
                                  .catch((error) => {
                                    console.log(`error`, error);
                                    alert(error.message);
                                  })
                                  .then(() => {
                                    setGroupCreationLoading(false);
                                  });
                              }
                            );
                          }}
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Create Group
                        </button>
                      </div>
                      {groupCreationLoading ? (
                        <LoadingScreen
                          loading="Attempting to create group"
                          message="Validating each of the fields provided please wait!"
                        />
                      ) : null}
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
