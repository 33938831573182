import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import LoadingScreen from "../../../../components/LoadingScreen";
import APIEndPoint from "../../../../utils/APIEndPoint";
import { useNavigate } from "react-router-dom";

export default function Users({ user: theCurrentUser } = {}) {
  const navigate = useNavigate();
  console.log(`Users theCurrentUser`, theCurrentUser);
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [users, setUsers] = useState([]);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserFirstName, setNewUserFirstName] = useState("");
  const [newUserSurname, setNewUserSurname] = useState("");
  const [newUserTitle, setNewUserTitle] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [newUserConfirmPassword, setNewUserConfirmPassword] = useState("");
  const [userCreationLoading, setUserCreationLoading] = useState("");

  useEffect(() => {
    const getData = setTimeout(() => {
      if (!loaded) {
        theCurrentUser.getSession((error, cognitoUserSession) => {
          console.log(`error`, error);
          const theAccessToken = cognitoUserSession.getAccessToken();
          // console.log(`theAccessToken.getJwtToken()`, theAccessToken.getJwtToken());
          console.log(
            `It is ${
              theAccessToken.getExpiration() > Date.now() / 1000
            } that session is still active for ${
              theAccessToken.getExpiration() - Date.now() / 1000
            } seconds`
          );
          var url = `${APIEndPoint}/api/user/all`;
          // var bearer = "Bearer " + bearer_token;
          fetch(url, {
            method: "GET",
            // withCredentials: true,
            // credentials: "include",
            headers: {
              Authorization: theAccessToken.getJwtToken(),
              // "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
              // "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then(({ success, users: gottenUsers }) => {
              if (!!success && Array.isArray(gottenUsers)) {
                setUsers(gottenUsers);
              }
            })
            .catch((error) => {
              console.log(`error`, error);
            })
            .then(() => {
              setLoaded(true);
            });
        });
      }
    }, 500);
    return () => clearTimeout(getData);
  }, [loaded, users]);
  // useEffect(() => {
  //   if (!loaded) {
  //     theCurrentUser.getSession((error, cognitoUserSession) => {
  //       console.log(`error`, error);
  //       const theAccessToken = cognitoUserSession.getAccessToken();
  //       // console.log(`theAccessToken.getJwtToken()`, theAccessToken.getJwtToken());
  //       console.log(
  //         `It is ${
  //           theAccessToken.getExpiration() > Date.now() / 1000
  //         } that session is still active for ${
  //           theAccessToken.getExpiration() - Date.now() / 1000
  //         } seconds`
  //       );
  //       var url = `${APIEndPoint}/api/user/all`;
  //       // var bearer = "Bearer " + bearer_token;
  //       fetch(url, {
  //         method: "GET",
  //         // withCredentials: true,
  //         // credentials: "include",
  //         headers: {
  //           Authorization: theAccessToken.getJwtToken(),
  //           // "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
  //           // "Content-Type": "application/json",
  //         },
  //       })
  //         .then((response) => response.json())
  //         .then(({ success, users: gottenUsers }) => {
  //           if (!!success && Array.isArray(gottenUsers)) {
  //             setUsers(gottenUsers);
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(`error`, error);
  //         })
  //         .then(() => {
  //           setLoaded(true);
  //         });
  //     });
  //   }
  // }, [loaded, users]);
  return (
    <div className="divide-y divide-gray-200 lg:col-span-9 py-6 px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Users</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their name, title,
            email and role.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => setOpen(true)}
          >
            Add new
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {users.map((person) => (
                    <tr key={person.emails[0].value}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {person.name.first}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.name.title}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.emails[0].value}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {person.role}
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <a
                          href="/"
                          onClick={(event) => {
                            event.preventDefault();
                            navigate(`/user/${person.id}`);
                          }}
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          Details
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {!loaded ? (
                <LoadingScreen
                  loading="Fetching Users"
                  message="Please wait, Users are being fetched."
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              New User
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">
                              Create a user for this platform.
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label
                                  htmlFor="new-user-email-input"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Email Address
                                </label>
                                <div className="mt-1">
                                  <input
                                    value={newUserEmail}
                                    onChange={(event) => {
                                      event.preventDefault();
                                      setNewUserEmail(event.target.value);
                                    }}
                                    type="text"
                                    name="new-user-email-input"
                                    id="new-user-email-input"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="new-user-first-name-input"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  First Name
                                </label>
                                <div className="mt-1">
                                  <input
                                    value={newUserFirstName}
                                    onChange={(event) => {
                                      event.preventDefault();
                                      setNewUserFirstName(event.target.value);
                                    }}
                                    type="text"
                                    name="new-user-first-name-input"
                                    id="new-user-first-name-input"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="new-user-surname-input"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Surname
                                </label>
                                <div className="mt-1">
                                  <input
                                    value={newUserSurname}
                                    onChange={(event) => {
                                      event.preventDefault();
                                      setNewUserSurname(event.target.value);
                                    }}
                                    type="text"
                                    name="new-user-surname-input"
                                    id="new-user-surname-input"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="new-user-title-input"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Title
                                </label>
                                <div className="mt-1">
                                  <input
                                    value={newUserTitle}
                                    onChange={(event) => {
                                      event.preventDefault();
                                      setNewUserTitle(event.target.value);
                                    }}
                                    type="text"
                                    name="new-user-title-input"
                                    id="new-user-title-input"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="new-user-password-input"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Password
                                </label>
                                <div className="mt-1">
                                  <input
                                    value={newUserPassword}
                                    onChange={(event) => {
                                      event.preventDefault();
                                      setNewUserPassword(event.target.value);
                                    }}
                                    type="password"
                                    name="new-user-password-input"
                                    id="new-user-password-input"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="new-user-confirm-password-input"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Confirm Password
                                </label>
                                <div className="mt-1">
                                  <input
                                    value={newUserConfirmPassword}
                                    onChange={(event) => {
                                      event.preventDefault();
                                      setNewUserConfirmPassword(
                                        event.target.value
                                      );
                                    }}
                                    type="password"
                                    name="new-user-confirm-password-input"
                                    id="new-user-confirm-password-input"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          onClick={(event) => {
                            event.preventDefault();
                            setUserCreationLoading(true);
                            if (!(newUserConfirmPassword === newUserPassword)) {
                              setUserCreationLoading(false);
                              return alert(
                                `Password and Confirm Password fields must match while creating a new user`
                              );
                            }
                            theCurrentUser.getSession(
                              (error, cognitoUserSession) => {
                                console.log(`error`, error);
                                if (!!error) {
                                  setUserCreationLoading(false);
                                  return alert(error.message);
                                }
                                const theAccessToken =
                                  cognitoUserSession.getAccessToken();
                                // console.log(`theAccessToken.getJwtToken()`, theAccessToken.getJwtToken());
                                console.log(
                                  `It is ${
                                    theAccessToken.getExpiration() >
                                    Date.now() / 1000
                                  } that session is still active for ${
                                    theAccessToken.getExpiration() -
                                    Date.now() / 1000
                                  } seconds`
                                );

                                // setNewUserTitle
                                fetch(`${APIEndPoint}/api/user?reset=true`, {
                                  method: "POST",
                                  body: JSON.stringify({
                                    email: newUserEmail,
                                    firstName: newUserFirstName,
                                    surname: newUserSurname,
                                    title: newUserTitle,
                                    password: newUserConfirmPassword,
                                  }),
                                  // withCredentials: true,
                                  // credentials: "include",
                                  headers: {
                                    Authorization: theAccessToken.getJwtToken(),
                                    // "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
                                    "Content-Type": "application/json",
                                  },
                                })
                                  .then((response) => response.json())
                                  .then(
                                    ({
                                      success,
                                      user,
                                      message = `An unexpected error occured`,
                                    }) => {
                                      if (!!success && Array.isArray(users)) {
                                        setUsers([user, ...users]);
                                        setOpen(false);
                                      }
                                      alert(message);
                                    }
                                  )
                                  .catch((error) => {
                                    console.log(`error`, error);
                                    alert(error.message);
                                  })
                                  .then(() => {
                                    setUserCreationLoading(false);
                                  });
                              }
                            );
                          }}
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Reset
                        </button>
                        <button
                          type="submit"
                          onClick={(event) => {
                            event.preventDefault();
                            setUserCreationLoading(true);
                            if (!(newUserConfirmPassword === newUserPassword)) {
                              setUserCreationLoading(false);
                              return alert(
                                `Password and Confirm Password fields must match while creating a new user`
                              );
                            }
                            theCurrentUser.getSession(
                              (error, cognitoUserSession) => {
                                console.log(`error`, error);
                                if (!!error) {
                                  setUserCreationLoading(false);
                                  return alert(error.message);
                                }
                                const theAccessToken =
                                  cognitoUserSession.getAccessToken();
                                // console.log(`theAccessToken.getJwtToken()`, theAccessToken.getJwtToken());
                                console.log(
                                  `It is ${
                                    theAccessToken.getExpiration() >
                                    Date.now() / 1000
                                  } that session is still active for ${
                                    theAccessToken.getExpiration() -
                                    Date.now() / 1000
                                  } seconds`
                                );

                                // setNewUserTitle
                                fetch(`${APIEndPoint}/api/user`, {
                                  method: "POST",
                                  body: JSON.stringify({
                                    email: newUserEmail,
                                    firstName: newUserFirstName,
                                    surname: newUserSurname,
                                    title: newUserTitle,
                                    password: newUserConfirmPassword,
                                  }),
                                  // withCredentials: true,
                                  // credentials: "include",
                                  headers: {
                                    Authorization: theAccessToken.getJwtToken(),
                                    // "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
                                    "Content-Type": "application/json",
                                  },
                                })
                                  .then((response) => response.json())
                                  .then(
                                    ({
                                      success,
                                      user,
                                      message = `An unexpected error occured`,
                                    }) => {
                                      if (!!success && Array.isArray(users)) {
                                        setUsers([user, ...users]);
                                        setOpen(false);
                                      }
                                      alert(message);
                                    }
                                  )
                                  .catch((error) => {
                                    console.log(`error`, error);
                                    alert(error.message);
                                  })
                                  .then(() => {
                                    setUserCreationLoading(false);
                                  });
                              }
                            );
                          }}
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Create
                        </button>
                      </div>
                      {userCreationLoading ? (
                        <LoadingScreen
                          loading="Attempting to create user"
                          message="Validating each of the fields provided please wait!"
                        />
                      ) : null}
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
