import { CookieStorage } from "amazon-cognito-identity-js";
const cookieData = {
  domain:
    process.env.NODE_ENV === "development" ? "localhost" : ".gpwidget.com", //localhost:8000
  secure: !(process.env.NODE_ENV === "development"),
  path: "/",
  expires: 365,
};

export default new CookieStorage(cookieData);
