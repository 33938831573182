import { Routes, Route, Link, useLocation } from "react-router-dom";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import Users from "./Users";
import UserDetails from "./Users/Details";
import Groups from "./Groups";
import GroupDetails from "./Groups/Details";
import Organizations from "./Organizations";
import OrganizationDetails from "./Organizations/Details";
import Roles from "./Roles";
import RoleDetails from "./Roles/Details";
import Admins from "./Admins";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function General({
  entities,
  setEntities,
  groupTypes,
  setGroupTypes,
  roles,
  setRoles,
  user: theCurrentUser,
  me,
}) {
  let location = useLocation();
  console.log("location", location);
  const subNavigation = [
    {
      name: "Organizations",
      href: "/organization",
      icon: UserCircleIcon,
      current: false,
    },
    { name: "Groups", href: "/group", icon: UserCircleIcon, current: false },
    { name: "Users", href: "/user", icon: UserCircleIcon, current: true },
    {
      name: "Roles",
      href: "/role",
      icon: UserCircleIcon,
      current: false,
    },
  ];
  if (
    Array.isArray(subNavigation) &&
    !!me &&
    !!Number(me.status) &&
    !!me.admin &&
    !!Number(me.admin.status)
  ) {
    subNavigation.push({
      name: "Admins",
      href: "/admin",
      icon: UserCircleIcon,
      current: true,
    });
  }
  return (
    <main className="relative -mt-32">
      <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
        <div className="overflow-hidden rounded-lg bg-white shadow">
          <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
            <aside className="py-6 lg:col-span-3">
              <nav className="space-y-1">
                {subNavigation.map((item, index) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      !!location.pathname.startsWith(item.href) ||
                        (index === 0 && location.pathname === `/`)
                        ? "bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700"
                        : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                      "group border-l-4 px-3 py-2 flex items-center text-sm font-medium"
                    )}
                    aria-current={
                      !!location.pathname.startsWith(item.href) ||
                      (index === 0 && location.pathname === `/`)
                        ? "page"
                        : undefined
                    }
                  >
                    <item.icon
                      className={classNames(
                        !!location.pathname.startsWith(item.href) ||
                          (index === 0 && location.pathname === `/`)
                          ? "text-teal-500 group-hover:text-teal-500"
                          : "text-gray-400 group-hover:text-gray-500",
                        "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    <span className="truncate">{item.name}</span>
                  </Link>
                ))}
              </nav>
            </aside>
            <Routes>
              <Route path="/">
                <Route
                  path=""
                  element={<Organizations user={theCurrentUser} me={me} />}
                />
                <Route
                  path="organization"
                  element={<Organizations user={theCurrentUser} me={me} />}
                />
                <Route
                  path="organization/:organizationId"
                  element={
                    <OrganizationDetails user={theCurrentUser} me={me} />
                  }
                />
                <Route
                  path="admin"
                  element={<Admins user={theCurrentUser} me={me} />}
                />
                <Route
                  path="user"
                  element={<Users user={theCurrentUser} me={me} />}
                />
                <Route
                  path="user/:userId"
                  element={<UserDetails user={theCurrentUser} me={me} />}
                />
                <Route
                  path="group"
                  element={
                    <Groups
                      {...{
                        groupTypes,
                        setGroupTypes,
                        user: theCurrentUser,
                        me,
                      }}
                    />
                  }
                />
                <Route
                  path="group/:groupId"
                  element={<GroupDetails user={theCurrentUser} me={me} />}
                />
                <Route
                  path="role"
                  element={
                    <Roles
                      {...{
                        entities,
                        setEntities,
                        roles,
                        setRoles,
                        me,
                        user: theCurrentUser,
                      }}
                    />
                  }
                />
                <Route
                  path="role/:roleId"
                  element={<RoleDetails user={theCurrentUser} me={me} />}
                />
              </Route>
            </Routes>
          </div>
        </div>
      </div>
    </main>
  );
}
