import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import CognitoCookieStorage from "./utils/CognitoCookieStorage";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { CheckIcon } from "@heroicons/react/24/outline";
import Unauthenticated from "./pages/Unauthenticated";
import LoadingScreen from "./components/LoadingScreen";
import Authenticated from "./pages/Authenticated";
import APIEndPoint from "./utils/APIEndPoint";

export default function App() {
  const [blockMessage, setBlockMessage] = useState("");
  const [fetchedUserPoolData, setFetchedUserPoolData] = useState(null);
  const [show, setShow] = useState(true);
  const [userPoolFetchCount, setUserPoolFetchCount] = useState(0);
  const [userPool, setUserPool] = useState(null);
  const [theCurrentUser, setCurrentUser] = useState(null);
  const [hasDecidedSession, setHasDecidedSession] = useState(false);
  const [isAuthenticated, setAuthenticatedState] = useState(false);
  const [isAnAdmin, setIsAnAdmin] = useState(false);
  const [coreRefresh, setCoreRefresh] = useState(Date.now());
  const [me, setMe] = useState(null);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (!userPool) {
        if (userPoolFetchCount < 6) {
          fetch(`${APIEndPoint}`, {
            method: "GET",
          })
            .then((response) => response.json())
            .then(
              ({
                success = false,
                // message: "General message fetched",
                region,
                cognitoUserPoolId,
                cognitoUserPoolClientId,
              } = {}) => {
                console.log(`Interesting happened`);
                if (
                  !!success &&
                  !!region &&
                  !!cognitoUserPoolId &&
                  !!cognitoUserPoolClientId
                ) {
                  const theNewCognitoUserPool = new CognitoUserPool({
                    UserPoolId: cognitoUserPoolId,
                    ClientId: cognitoUserPoolClientId,
                    Storage: CognitoCookieStorage,
                    endpoint: `https://cognito-idp.${region}.amazonaws.com/`,
                  });
                  const localJagba = theNewCognitoUserPool.getCurrentUser();
                  console.log(`localJagba`, localJagba);
                  setCurrentUser(localJagba);
                  setUserPool(theNewCognitoUserPool);
                  setFetchedUserPoolData({
                    region,
                    cognitoUserPoolId,
                    cognitoUserPoolClientId,
                  });
                }
              }
            )
            .catch((error) => {
              console.log(`error`, error);
            })
            .then(() => {
              setUserPoolFetchCount(userPoolFetchCount + 1);
            });
        } else {
          console.log(`Gbege shele ni ooo`);
        }
      } else if (
        !hasDecidedSession &&
        !!theCurrentUser &&
        !!theCurrentUser.username
      ) {
        theCurrentUser.getSession((error, cognitoUserSession) => {
          console.log(`error`, error);
          let theAccessToken;
          const authenticationState =
            !!cognitoUserSession &&
            typeof cognitoUserSession.isValid === "function" &&
            cognitoUserSession.isValid() &&
            ((theAccessToken = cognitoUserSession.getAccessToken()),
            theAccessToken.getExpiration() > Date.now() / 1000);
          if (!authenticationState) {
            return theCurrentUser.signOut(() => {
              window.location.reload();
            });
          }
          var url = `${APIEndPoint}/api/me`;
          fetch(url, {
            method: "GET",
            headers: {
              Authorization: theAccessToken.getJwtToken(),
            },
          })
            .then((response) => response.json())
            .then(({ success, message, isAuthenticated, me, isAdmin } = {}) => {
              setBlockMessage(message || "");
              setAuthenticatedState(!!isAuthenticated);
              setIsAnAdmin(!!isAdmin);
              if (!!me) {
                setMe(me);
              }
            })
            .catch((error) => {
              console.log(`error`, error);
            })
            .then(() => {
              setHasDecidedSession(true);
            });
        });
      } else if (
        !!hasDecidedSession &&
        !!theCurrentUser &&
        !!theCurrentUser.username &&
        !isAuthenticated
      ) {
        setHasDecidedSession(false);
      } else if (!hasDecidedSession) {
        setHasDecidedSession(true);
      }
    }, 500);
    return () => clearTimeout(getData);
  }, [
    userPoolFetchCount,
    userPool,
    hasDecidedSession,
    theCurrentUser,
    isAuthenticated,
  ]);
  return (
    <>
      {!!userPool ? (
        !!hasDecidedSession ? (
          !!isAuthenticated ? (
            // isAnAdmin ? (
            //   <Authenticated user={theCurrentUser} me={me} />
            // ) : (
            //   <Transition.Root show={true} as={Fragment}>
            //     <Dialog
            //       as="div"
            //       className="relative z-10"
            //       onClose={(event) => {
            //         event.preventDefault();
            //       }}
            //     >
            //       <Transition.Child
            //         as={Fragment}
            //         enter="ease-out duration-300"
            //         enterFrom="opacity-0"
            //         enterTo="opacity-100"
            //         leave="ease-in duration-200"
            //         leaveFrom="opacity-100"
            //         leaveTo="opacity-0"
            //       >
            //         <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            //       </Transition.Child>

            //       <div className="fixed inset-0 z-10 overflow-y-auto">
            //         <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            //           <Transition.Child
            //             as={Fragment}
            //             enter="ease-out duration-300"
            //             enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            //             enterTo="opacity-100 translate-y-0 sm:scale-100"
            //             leave="ease-in duration-200"
            //             leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            //             leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            //           >
            //             <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
            //               <div>
            //                 <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
            //                   <CheckIcon
            //                     className="h-6 w-6 text-green-600"
            //                     aria-hidden="true"
            //                   />
            //                 </div>
            //                 <div className="mt-3 text-center sm:mt-5">
            //                   <Dialog.Title
            //                     as="h3"
            //                     className="text-lg font-medium leading-6 text-gray-900"
            //                   >
            //                     {`Welcome! ${me.name.first}`}
            //                   </Dialog.Title>
            //                   <div className="mt-2">
            //                     <p className="text-sm text-gray-500">
            //                       {`This App is available to system Administrators only, so ${me.name.first}, kindly navigate to the areas you have entry access to.`}
            //                     </p>
            //                   </div>
            //                 </div>
            //               </div>
            //               <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            //                 <button
            //                   type="button"
            //                   className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            //                   onClick={(event) => {
            //                     event.preventDefault();
            //                     window.location.reload();
            //                   }}
            //                 >
            //                   Reload page
            //                 </button>
            //                 <button
            //                   type="button"
            //                   className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
            //                   onClick={(event) => {
            //                     event.preventDefault();
            //                     theCurrentUser.signOut(() => {
            //                       window.location.reload();
            //                     });
            //                   }}
            //                 >
            //                   Logout
            //                 </button>
            //               </div>
            //             </Dialog.Panel>
            //           </Transition.Child>
            //         </div>
            //       </div>
            //     </Dialog>
            //   </Transition.Root>
            // )
            <Authenticated
              {...{ coreRefresh, setCoreRefresh, user: theCurrentUser, me }}
            />
          ) : (
            <Unauthenticated {...{ coreRefresh, setCoreRefresh, userPool }} />
          )
        ) : (
          <LoadingScreen />
        )
      ) : (
        <LoadingScreen />
      )}
      {/* Global notification live region, render this permanently at the end of the document */}
      {[
        // "jargons", "woliagba"
      ].map((item, key) => {
        return (
          <div
            key={key}
            aria-live="assertive"
            className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
          >
            <div
              className={
                "flex w-full flex-col items-center space-y-4 sm:items-end"
              }
            >
              {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
              <Transition
                show={show}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="p-4">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        <CheckCircleIcon
                          className="h-6 w-6 text-green-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-gray-900">
                          Successfully saved!
                        </p>
                        <p className="mt-1 text-sm text-gray-500">{item}</p>
                      </div>
                      <div className="ml-4 flex flex-shrink-0">
                        <button
                          type="button"
                          className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => {
                            setShow(false);
                          }}
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        );
      })}
    </>
  );
}
