import { Fragment, useEffect, useState } from "react";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import qs from "qs";
import {
  LinkIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import LoadingScreen from "../../../../components/LoadingScreen";
import APIEndPoint from "../../../../utils/APIEndPoint";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Roles({
  roles,
  setRoles,
  entities,
  setEntities,
  me,
  user: theCurrentUser,
} = {}) {
  const navigate = useNavigate();
  let initialLoading = true;
  if (
    !!entities &&
    Object.keys(entities).length > 0 &&
    !!roles &&
    Object.keys(roles).length > 0
  ) {
    initialLoading = false;
  }
  const [mainResourceLoading, setMainResourceLoading] =
    useState(initialLoading);
  const [creatingNewRole, setCreatingNewRole] = useState(false);
  const [selectedTargets, setSelectedTargets] = useState({});
  const [targetSearchBy, setTargetSearchBy] = useState({});
  const [targetSearchResults, setTargetSearchResults] = useState({});
  const [showTargetSearchResults, setShowTargetSearchResults] = useState({});
  const [activeField, setActiveField] = useState(null);
  const [targetSearchTerm, setTargetSearchTerm] = useState("");
  const [roleToCreateNewOf, setRoleToCreateNewOf] = useState(null);
  const [canPassRole, setCanPassRole] = useState(0);
  const valueTypeToPositionMap = {};
  if (
    !isNaN(roleToCreateNewOf) &&
    !(roleToCreateNewOf == null) &&
    Array.isArray(roles[roleToCreateNewOf][1])
  ) {
    roles[roleToCreateNewOf][1].forEach(([currentType], index) => {
      valueTypeToPositionMap[currentType] = index;
    });
  }
  useEffect(() => {
    const getData = setTimeout(() => {
      if (mainResourceLoading) {
        theCurrentUser.getSession((error, cognitoUserSession) => {
          console.log(`error`, error);
          const theAccessToken = cognitoUserSession.getAccessToken();
          // console.log(`theAccessToken.getJwtToken()`, theAccessToken.getJwtToken());
          console.log(
            `It is ${
              theAccessToken.getExpiration() > Date.now() / 1000
            } that session is still active for ${
              theAccessToken.getExpiration() - Date.now() / 1000
            } seconds`
          );
          var url = `${APIEndPoint}/api/role/all`;
          // var bearer = "Bearer " + bearer_token;
          fetch(url, {
            method: "GET",
            // withCredentials: true,
            // credentials: "include",
            headers: {
              Authorization: theAccessToken.getJwtToken(),
              // "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
              // "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then(
              ({ success, roles: gottenRoles, entities: gottenEntities }) => {
                if (!!success && !!gottenRoles) {
                  setRoles(gottenRoles);
                }
                if (!!success && !!gottenEntities) {
                  setEntities(gottenEntities);
                }
              }
            )
            .catch((error) => {
              console.log(`error`, error);
            })
            .then(() => {
              setMainResourceLoading(false);
            });
        });
      }
    }, 500);
    return () => clearTimeout(getData);
  }, [mainResourceLoading]);

  useEffect(() => {
    // { keyAppendum, entities }
    const getData = setTimeout(() => {
      console.log(`Wetin dey shele?? 0001`);
      if (
        !!me &&
        // !!me.admin &&
        // Number(me.admin.status) === 1 &&
        !!activeField &&
        !!targetSearchBy &&
        !!activeField.keyAppendum &&
        !!targetSearchTerm &&
        typeof targetSearchTerm[activeField.keyAppendum] === "string" &&
        targetSearchTerm[activeField.keyAppendum].length > 1
      ) {
        console.log(`Wetin dey shele?? 0001`);
        theCurrentUser.getSession((error, cognitoUserSession) => {
          console.log(`error`, error);
          const theAccessToken = cognitoUserSession.getAccessToken();
          // console.log(`theAccessToken.getJwtToken()`, theAccessToken.getJwtToken());
          console.log(
            `It is ${
              theAccessToken.getExpiration() > Date.now() / 1000
            } that session is still active for ${
              theAccessToken.getExpiration() - Date.now() / 1000
            } seconds`
          );
          // var url = `${APIEndPoint}/api/user/search/by/${
          //   targetSearchBy[activeField.keyAppendum]
          // }?${qs.stringify({
          //   value: targetSearchTerm[activeField.keyAppendum],
          // })}`;
          // var url = `${APIEndPoint}/api/field/${
          //   activeField.fieldKind
          // }/vector/${targetSearchBy[activeField.keyAppendum]}?${qs.stringify({
          //   value: targetSearchTerm[activeField.keyAppendum],
          // })}`;
          // currentDependencyRule
          const queryObject = {
            value: targetSearchTerm[activeField.keyAppendum],
          };
          if (
            Array.isArray(activeField.currentDependencyRule) &&
            activeField.currentDependencyRule.length >= 3
          ) {
            const [typeOfValue, attributeRequired, filterRule] =
              activeField.currentDependencyRule || [];
            const nnnnKey = `${roleToCreateNewOf}-${valueTypeToPositionMap[typeOfValue]}`;
            // valueTypeToPositionMap
            // positionOfValueType
            if (
              !!selectedTargets[nnnnKey] &&
              selectedTargets[nnnnKey][attributeRequired] != undefined
            ) {
              queryObject[
                `filter`
              ] = `${filterRule}§§§${selectedTargets[nnnnKey][attributeRequired]}`;
            }
          }
          var url = `${APIEndPoint}/api/field/${activeField.fieldKind}/vector/${
            activeField.vectorId
          }?${qs.stringify(queryObject)}`;
          // var bearer = "Bearer " + bearer_token;
          fetch(url, {
            method: "GET",
            // withCredentials: true,
            // credentials: "include",
            headers: {
              Authorization: theAccessToken.getJwtToken(),
              // "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
              // "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then(({ success, result: gottenResult }) => {
              if (!!success && Array.isArray(gottenResult)) {
                setTargetSearchResults({
                  ...targetSearchResults,
                  [activeField.keyAppendum]: gottenResult,
                });
              }
            })
            .catch((error) => {
              console.log(`error`, error);
            });
        });
      }
    }, 500);
    return () => clearTimeout(getData);
  }, [activeField, targetSearchTerm]);

  let canSubmitRole = false;
  let submitable = {};
  const assignRoleView =
    !isNaN(roleToCreateNewOf) && !(roleToCreateNewOf == null)
      ? (() => {
          const possibleRolePassingDecisions = ["No", "Yes"]; // To possibleFields
          let possibleFields = ["To", "On", "Using"];
          let finalDiplay = [];
          for (
            let currentPosition = 0;
            currentPosition < roles[roleToCreateNewOf][1].length;
            currentPosition++
          ) {
            const keyAppendum = `${roleToCreateNewOf}-${currentPosition}`;
            const currentType = roles[roleToCreateNewOf][1][currentPosition][0];
            const currentEntityId =
              roles[roleToCreateNewOf][1][currentPosition][1];
            const currentRequirement =
              roles[roleToCreateNewOf][1][currentPosition][2];
            //0:Field Not Required, 1: Field Must be provided on client display, it may be 0: Any,1: Specific
            const currentDependencyRule =
              roles[roleToCreateNewOf][1][currentPosition][3];
            const possibleDecisions = ["No", "Yes"]; // To possibleFields
            const requirementKeyAppendum = `${keyAppendum}-0`;
            const whetherSpecific = Number(
              !!selectedTargets[requirementKeyAppendum]
            );
            if (
              !Array.isArray(entities[currentEntityId]) ||
              !Array.isArray(entities[currentEntityId][2]) ||
              !Array.isArray(entities[currentEntityId][2][0]) ||
              !entities[currentEntityId][2][0][1]
            ) {
              if (currentPosition === 2) {
                canSubmitRole = true;
              }
              finalDiplay.push(
                <div
                  key={keyAppendum}
                  className="pointer-events-auto w-[25rem] rounded-lg bg-white p-4 text-[0.8125rem] leading-5 shadow-xl shadow-black/5 hover:bg-slate-50 ring-2 ring-indigo-600"
                >
                  <div className="flex justify-between">
                    <div className="font-medium text-slate-900">
                      {`${possibleFields[currentType]} (${entities[currentEntityId][0]})`}
                    </div>
                    <svg className="h-5 w-5 flex-none" fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.707-9.293a1 1 0 0 0-1.414-1.414L9 10.586 7.707 9.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4Z"
                        fill="#4F46E5"
                      ></path>
                    </svg>
                  </div>
                  <div className="mt-3 font-medium text-slate-900">
                    <div
                      className={
                        "text-white bg-indigo-600 relative cursor-default select-none py-2 pl-3 pr-9 mb-2"
                      }
                    >
                      {`${possibleFields[currentType]} (${entities[currentEntityId][0]})`}
                      <span className={"font-semibold block truncate"}>
                        {`${entities[currentEntityId][0]}`}
                      </span>
                      <small></small>
                    </div>
                  </div>
                </div>
              );
              continue;
            }
            const possibleTargetSearchVectorsKeys = Object.keys(
              entities[currentEntityId][2][0][1]
            );

            const localSearchBy =
              !!targetSearchBy[keyAppendum] &&
              !!targetSearchBy &&
              Array.isArray(entities[currentEntityId][2]) &&
              !!entities[currentEntityId][2][0][1][
                targetSearchBy[keyAppendum]
              ] &&
              typeof entities[currentEntityId][2][0][1][
                targetSearchBy[keyAppendum]
              ] === "string"
                ? targetSearchBy[keyAppendum]
                : !!possibleTargetSearchVectorsKeys.length
                ? possibleTargetSearchVectorsKeys[0]
                : 0;
            let subList = [];
            if (Number(currentRequirement) === 0) {
              subList.push(
                <Listbox
                  key={keyAppendum}
                  value={whetherSpecific}
                  onChange={(value) => {
                    setSelectedTargets({
                      ...selectedTargets,
                      [requirementKeyAppendum]: value,
                    });
                  }}
                >
                  {({ open }) => (
                    <>
                      <Listbox.Label className="block text-sm font-medium text-gray-700">
                        {`Would you rather be specific about field "${possibleFields[currentType]}"`}
                      </Listbox.Label>
                      <div className="relative mt-1">
                        <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                          <span className="block truncate">
                            {possibleDecisions[whetherSpecific]}
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {["No", "Yes"].map((name, key) => (
                              <Listbox.Option
                                key={key}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? "text-white bg-indigo-600"
                                      : "text-gray-900",
                                    "relative cursor-default select-none py-2 pl-3 pr-9"
                                  )
                                }
                                value={key}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "block truncate"
                                      )}
                                    >
                                      {name}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? "text-white"
                                            : "text-indigo-600",
                                          "absolute inset-y-0 right-0 flex items-center pr-4"
                                        )}
                                      >
                                        <CheckIcon
                                          className="h-5 w-5"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              );
              if (!whetherSpecific) {
                if (currentPosition === 2) {
                  canSubmitRole = true;
                }
                finalDiplay.push(
                  <div
                    key={requirementKeyAppendum}
                    className="pointer-events-auto w-[25rem] rounded-lg bg-white p-4 text-[0.8125rem] leading-5 shadow-xl shadow-black/5 hover:bg-slate-50 ring-2 ring-indigo-600"
                  >
                    <div className="flex justify-between">
                      <div className="font-medium text-slate-900">
                        {`${possibleFields[currentType]} (${entities[currentEntityId][0]})`}
                      </div>
                      <svg className="h-5 w-5 flex-none" fill="none">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.707-9.293a1 1 0 0 0-1.414-1.414L9 10.586 7.707 9.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4Z"
                          fill="#4F46E5"
                        ></path>
                      </svg>
                    </div>
                    {/* <div className="mt-1 text-slate-700">Last message sent an hour ago</div> */}
                    <div className="mt-3 font-medium text-slate-900">
                      {subList[0]}
                    </div>
                  </div>
                );
                break;
              }
            }
            subList.push(
              !!selectedTargets[keyAppendum] ? (
                <div
                  key={requirementKeyAppendum}
                  className={
                    "text-white bg-indigo-600 relative cursor-default select-none py-2 pl-3 pr-9 mb-2"
                  }
                >
                  {`${possibleFields[currentType]} (${entities[currentEntityId][0]})`}
                  <span className={"font-semibold block truncate"}>
                    {`${selectedTargets[keyAppendum].name}`}
                  </span>
                  <small></small>
                  <span
                    onClick={(event) => {
                      event.preventDefault();
                      setSelectedTargets({
                        ...selectedTargets,
                        [keyAppendum]: null,
                      });
                      setSelectedTargets({
                        ...selectedTargets,
                        [keyAppendum]: null,
                      });
                    }}
                    className={
                      "text-white absolute inset-y-0 right-0 flex items-center pr-4"
                    }
                  >
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </span>
                </div>
              ) : (
                <div key={currentPosition}>
                  {!selectedTargets || !selectedTargets[keyAppendum] ? (
                    <div>
                      <div className="mt-1">
                        <Listbox
                          value={localSearchBy}
                          onChange={(value) => {
                            setTargetSearchTerm({
                              ...targetSearchTerm,
                              [keyAppendum]: "",
                            });
                            setTargetSearchBy({
                              ...targetSearchBy,
                              [keyAppendum]: value,
                            });
                          }}
                        >
                          {({ open }) => (
                            <>
                              <Listbox.Label className="block text-sm font-medium text-gray-700">
                                {/* {`Search By (based on ${entities[currentEntityId][0]})`} */}
                                {`${possibleFields[currentType]} (Search for ${entities[currentEntityId][0]})`}
                              </Listbox.Label>
                              <div className="relative mt-1">
                                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                  <span className="block truncate">
                                    {
                                      entities[currentEntityId][2][0][1][
                                        possibleTargetSearchVectorsKeys[
                                          localSearchBy
                                        ]
                                      ]
                                    }
                                  </span>
                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {possibleTargetSearchVectorsKeys.map(
                                      (currentTargetSearchVectorKey) => (
                                        <Listbox.Option
                                          key={currentTargetSearchVectorKey}
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? "text-white bg-indigo-600"
                                                : "text-gray-900",
                                              "relative cursor-default select-none py-2 pl-3 pr-9"
                                            )
                                          }
                                          value={currentTargetSearchVectorKey}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <span
                                                className={classNames(
                                                  selected
                                                    ? "font-semibold"
                                                    : "font-normal",
                                                  "block truncate"
                                                )}
                                              >
                                                {
                                                  entities[
                                                    currentEntityId
                                                  ][2][0][1][
                                                    possibleTargetSearchVectorsKeys[
                                                      currentTargetSearchVectorKey
                                                    ]
                                                  ]
                                                }
                                              </span>

                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    active
                                                      ? "text-white"
                                                      : "text-indigo-600",
                                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      )
                                    )}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                      </div>
                    </div>
                  ) : null}
                  <div className="mt-1 mb-3">
                    <label
                      htmlFor="new-admin-search-term-input"
                      className="block text-sm font-medium text-gray-900"
                    >
                      {/* {`Search (based on ${entities[currentEntityId][0]})`} */}
                      {/* {`Using query ${entities[currentEntityId][0]}`} */}
                      {`Using query`}
                    </label>
                    <div className="relative mt-1">
                      <input
                        value={
                          !!targetSearchTerm &&
                          typeof targetSearchTerm[keyAppendum] === "string"
                            ? targetSearchTerm[keyAppendum]
                            : ""
                        }
                        onChange={(event) => {
                          event.preventDefault();
                          setTargetSearchTerm({
                            ...targetSearchTerm,
                            [keyAppendum]: event.target.value,
                          });
                          // targetSearchBy[activeField.keyAppendum]
                          setActiveField({
                            vectorId: localSearchBy,
                            fieldKind: entities[currentEntityId][2][0][2],
                            keyAppendum,
                            currentDependencyRule,
                          });
                        }}
                        onFocus={(event) => {
                          event.preventDefault();
                          setShowTargetSearchResults({
                            ...showTargetSearchResults,
                            [keyAppendum]: true,
                          });
                        }}
                        onBlur={(event) => {
                          event.preventDefault();
                        }}
                        type="text"
                        placeholder={`By ${
                          entities[currentEntityId][2][0][1][
                            possibleTargetSearchVectorsKeys[localSearchBy]
                          ]
                        }`}
                        name="new-admin-search-term-input"
                        id="new-admin-search-term-input"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                      {!!showTargetSearchResults &&
                      !!showTargetSearchResults[keyAppendum] ? (
                        <ul className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {!!targetSearchResults &&
                          Array.isArray(targetSearchResults[keyAppendum])
                            ? targetSearchResults[keyAppendum].map(
                                (target, key) => {
                                  return !!target.id && !!target.name ? (
                                    <li
                                      key={key}
                                      className={
                                        "hover:bg-indigo-600 text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9"
                                      }
                                      onClick={(event) => {
                                        event.preventDefault();
                                        setSelectedTargets({
                                          ...selectedTargets,
                                          [keyAppendum]: target,
                                        });
                                        setSelectedTargets({
                                          ...selectedTargets,
                                          [keyAppendum]: target,
                                        });
                                        setShowTargetSearchResults({
                                          ...showTargetSearchResults,
                                          [keyAppendum]: false,
                                        });
                                        setActiveField(null);
                                      }}
                                    >
                                      <span
                                        className={"font-normal block truncate"}
                                      >
                                        {`${target.name}`}
                                      </span>
                                    </li>
                                  ) : null;
                                }
                              )
                            : null}
                        </ul>
                      ) : null}
                    </div>
                  </div>
                </div>
              )
            );
            finalDiplay.push(
              <div
                key={keyAppendum}
                className="pointer-events-auto w-[25rem] rounded-lg bg-white p-4 text-[0.8125rem] leading-5 shadow-xl shadow-black/5 hover:bg-slate-50 ring-2 ring-indigo-600"
              >
                <div className="flex justify-between">
                  <div className="font-medium text-slate-900">
                    {`${possibleFields[currentType]} (${entities[currentEntityId][0]})`}
                  </div>
                  <svg className="h-5 w-5 flex-none" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.707-9.293a1 1 0 0 0-1.414-1.414L9 10.586 7.707 9.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4Z"
                      fill="#4F46E5"
                    ></path>
                  </svg>
                </div>
                {/* <div className="mt-1 text-slate-700">Last message sent an hour ago</div> */}
                <div className="mt-3 font-medium text-slate-900">{subList}</div>
              </div>
            );
            // [
            //   0, //Type of value --- 0: Holder, 1: Target, 2:Context
            //   4, //Entity Id as in entity.js
            //   1, //0:Field Not Required, 1: Field Must be provided on client display, it may be 0: Any,1: Specific
            // ],
            if (!selectedTargets[keyAppendum]) {
              submitable[currentType] = [];
              break;
            }
            if (!!selectedTargets[keyAppendum].id) {
              if (currentPosition === 2) {
                canSubmitRole = true;
              }
              // if(Array.isArray(currentDependencyRule)&&currentDependencyRule.length>)
              if (
                Array.isArray(currentDependencyRule) &&
                currentDependencyRule.length >= 3
              ) {
                const [typeOfValue, attributeRequired, filterRule] =
                  currentDependencyRule || [];
                const nnnnKey = `${roleToCreateNewOf}-${valueTypeToPositionMap[typeOfValue]}`;
                // valueTypeToPositionMap
                // positionOfValueType
                if (
                  !!selectedTargets[nnnnKey] &&
                  selectedTargets[nnnnKey][attributeRequired] != undefined
                ) {
                  submitable[currentType] = [
                    selectedTargets[keyAppendum].id,
                    selectedTargets[nnnnKey][attributeRequired],
                  ];
                }
              } else {
                submitable[currentType] = [selectedTargets[keyAppendum].id];
              }
            }
            // currentPosition
            // submitable
            // canSubmitRole
            // currentType
            // currentEntityId
            // currentRequirement
            // currentDependencyRule
          }
          console.log(`submitable submitable`, submitable);
          console.log(`canSubmitRole canSubmitRole`, canSubmitRole);
          if (!!canSubmitRole && !!Number(roles[roleToCreateNewOf][2])) {
            finalDiplay.push(
              <Listbox
                value={canPassRole}
                onChange={(value) => {
                  setCanPassRole(value);
                }}
              >
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-sm font-medium text-gray-700">
                      {`Can "${
                        selectedTargets[
                          `${roleToCreateNewOf}-${valueTypeToPositionMap[0]}`
                        ].name
                      }" pass this role to ${
                        entities[
                          roles[roleToCreateNewOf][1][
                            valueTypeToPositionMap[0]
                          ][1]
                        ][0]
                      }?`}
                    </Listbox.Label>
                    <div className="relative mt-1">
                      <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                        <span className="block truncate">
                          {possibleRolePassingDecisions[canPassRole]}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {possibleRolePassingDecisions.map((name, key) => (
                            <Listbox.Option
                              key={key}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "text-white bg-indigo-600"
                                    : "text-gray-900",
                                  "relative cursor-default select-none py-2 pl-3 pr-9"
                                )
                              }
                              value={key}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected
                                        ? "font-semibold"
                                        : "font-normal",
                                      "block truncate"
                                    )}
                                  >
                                    {name}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-white"
                                          : "text-indigo-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            );
          }
          return finalDiplay;
        })()
      : null;
  return (
    <div className="divide-y divide-gray-200 lg:col-span-9 py-6 px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Roles</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their name, title,
            email and role.
          </p>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Id
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Owner Type
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Target Type
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Context Type
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {!!roles
                    ? Object.keys(roles).map((key) => {
                        let ownerType;
                        let targetType;
                        let contextType;
                        roles[key][1].forEach(([type, entityId]) => {
                          if (type === 0) {
                            ownerType = entityId;
                            return;
                          }
                          if (type === 1) {
                            targetType = entityId;
                            return;
                          }
                          if (type === 2) {
                            contextType = entityId;
                            return;
                          }
                        });
                        return (
                          <tr key={key}>
                            <td
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              {key}
                            </td>
                            <td
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              {/* {roles[key][1][1]} */}
                              {roles[key][0]}
                            </td>
                            <td
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              {entities[ownerType][0]}
                            </td>
                            <td
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              {entities[targetType][0]}
                            </td>
                            <td
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              {entities[contextType][0]}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <a
                                href="/"
                                onClick={(event) => {
                                  event.preventDefault();
                                  navigate(`/role/${key}`);
                                }}
                                className="text-indigo-600 hover:text-indigo-900 mr-3"
                              >
                                Details
                              </a>
                              <a
                                href="/"
                                onClick={(event) => {
                                  event.preventDefault();
                                  setRoleToCreateNewOf(key);
                                }}
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                New
                              </a>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
              {mainResourceLoading ? (
                <LoadingScreen
                  mainResourceLoading="Fetching all possible roles"
                  message="Please wait, we are fetching all possible roles on the system."
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {!isNaN(roleToCreateNewOf) && !(roleToCreateNewOf == null) ? (
        <Transition.Root show={!isNaN(roleToCreateNewOf)} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              setRoleToCreateNewOf(null);
            }}
          >
            <div className="fixed inset-0" />

            <div className="fixed inset-0 overflow-hidden">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                      <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                        <div className="h-0 flex-1 overflow-y-auto">
                          <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                            <div className="flex items-center justify-between">
                              <Dialog.Title className="text-lg font-medium text-white">
                                {`Assign role`}
                              </Dialog.Title>
                              <div className="ml-3 flex h-7 items-center">
                                <button
                                  type="button"
                                  className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                  onClick={() => {
                                    setRoleToCreateNewOf(null);
                                  }}
                                >
                                  <span className="sr-only">Close panel</span>
                                  <XMarkIcon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="mt-1">
                              <p className="text-sm text-indigo-300">
                                {`${roles[roleToCreateNewOf][0]}`}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-1 flex-col justify-between">
                            <div className="px-4 sm:px-6">
                              <div className="space-y-6 pt-6 pb-5">
                                {assignRoleView}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => {
                              setRoleToCreateNewOf(null);
                            }}
                          >
                            Cancel
                          </button>
                          {!!canSubmitRole ? (
                            <button
                              type="submit"
                              disabled={!canSubmitRole}
                              onClick={(event) => {
                                event.preventDefault();

                                theCurrentUser.getSession(
                                  (error, cognitoUserSession) => {
                                    console.log(`error`, error);
                                    const theAccessToken =
                                      cognitoUserSession.getAccessToken();
                                    // console.log(`theAccessToken.getJwtToken()`, theAccessToken.getJwtToken());
                                    console.log(
                                      `It is ${
                                        theAccessToken.getExpiration() >
                                        Date.now() / 1000
                                      } that session is still active for ${
                                        theAccessToken.getExpiration() -
                                        Date.now() / 1000
                                      } seconds`
                                    );
                                    setCreatingNewRole(true);

                                    fetch(
                                      `${APIEndPoint}/api/role/${roleToCreateNewOf}`,
                                      {
                                        method: "POST",
                                        body: JSON.stringify({
                                          data: submitable,
                                          canPass: canPassRole,
                                        }),
                                        // withCredentials: true,
                                        // credentials: "include",
                                        headers: {
                                          Authorization:
                                            theAccessToken.getJwtToken(),
                                          // "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
                                          "Content-Type": "application/json",
                                        },
                                      }
                                    )
                                      .then((response) => response.json())
                                      .then(
                                        ({
                                          success,
                                          message = `An unexpected error occured`,
                                        }) => {
                                          if (!!success) {
                                            setRoleToCreateNewOf(null);
                                          }
                                          alert(message);
                                        }
                                      )
                                      .catch((error) => {
                                        console.log(`error`, error);
                                        alert(error.message);
                                      })
                                      .then(() => {
                                        //creatingNewRole
                                        setCreatingNewRole(false); // submitable
                                      });
                                  }
                                );
                              }}
                              className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              Assign Role
                            </button>
                          ) : null}
                        </div>
                        {creatingNewRole ? (
                          <LoadingScreen
                            loading="Attempting to assign role"
                            message="Validating each of the fields provided please wait!"
                          />
                        ) : null}
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      ) : null}
    </div>
  );
}
