import { Fragment, useEffect, useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import qs from "qs";
import {
  LinkIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/20/solid";
import LoadingScreen from "../../../../components/LoadingScreen";
import APIEndPoint from "../../../../utils/APIEndPoint";
import { useNavigate } from "react-router-dom";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Organizations({ user: theCurrentUser, me } = {}) {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [
    [
      organizationInvites,
      ownOrganizations,
      activeMemberships,
      joinRequests,
      allOrganizations,
    ],
    setOrganizations,
  ] = useState([[], [], [], [], []]);
  const [searchBy, setSearchBy] = useState(0);
  const [createOrganizationFor, setCreateOrganizationFor] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [userSearchResult, setUserSearchResult] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showUserSearchResult, setShowUserSearchResult] = useState(false);
  const theTabs = [
    "Invites",
    "Own",
    "Active Membership",
    "Join Requests",
    "Others",
  ];
  const tabExplanations = [
    "Organizations you have been invited to",
    "Your Own Organizations",
    "Organizations you are actively member of",
    "Organizations you requested to join",
    "Other Organizations on the System",
  ];
  const possibleOrganizationCreationVectors = ["Myself", "Another User"];
  const possibleUserSearchVectors = [
    "First Name",
    "Last Name",
    "Email",
    "Title",
  ];
  const [organizationCreationLoading, setOrganizationCreationLoading] =
    useState("");

  useEffect(() => {
    const getData = setTimeout(() => {
      if (
        !!me &&
        // && !!me.admin && Number(me.admin.status) === 1
        !loaded
      ) {
        theCurrentUser.getSession((error, cognitoUserSession) => {
          console.log(`error`, error);
          const theAccessToken = cognitoUserSession.getAccessToken();
          // console.log(`theAccessToken.getJwtToken()`, theAccessToken.getJwtToken());
          console.log(
            `It is ${
              theAccessToken.getExpiration() > Date.now() / 1000
            } that session is still active for ${
              theAccessToken.getExpiration() - Date.now() / 1000
            } seconds`
          );
          var url = `${APIEndPoint}/api/organization/${activeTab || 0}`;
          // var bearer = "Bearer " + bearer_token;
          fetch(url, {
            method: "GET",
            // withCredentials: true,
            // credentials: "include",
            headers: {
              Authorization: theAccessToken.getJwtToken(),
              // "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
              // "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then(({ success, result: gottenResult }) => {
              if (!!success && Array.isArray(gottenResult)) {
                setOrganizations([
                  activeTab === 0 ? gottenResult : organizationInvites,
                  activeTab === 1 ? gottenResult : ownOrganizations,
                  activeTab === 2 ? gottenResult : activeMemberships,
                  activeTab === 3 ? gottenResult : joinRequests,
                  activeTab === 4 ? gottenResult : allOrganizations,
                ]);
              }
            })
            .catch((error) => {
              console.log(`error`, error);
            })
            .then(() => {
              setLoaded(true);
            });
        });
      }
    }, 500);
    return () => clearTimeout(getData);
  }, [
    loaded,
    organizationInvites,
    ownOrganizations,
    activeMemberships,
    joinRequests,
    allOrganizations,
  ]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (
        !!me &&
        // !!me.admin &&
        // Number(me.admin.status) === 1 &&
        !!searchTerm &&
        typeof searchTerm === "string" &&
        searchTerm.length > 1
      ) {
        theCurrentUser.getSession((error, cognitoUserSession) => {
          console.log(`error`, error);
          const theAccessToken = cognitoUserSession.getAccessToken();
          // console.log(`theAccessToken.getJwtToken()`, theAccessToken.getJwtToken());
          console.log(
            `It is ${
              theAccessToken.getExpiration() > Date.now() / 1000
            } that session is still active for ${
              theAccessToken.getExpiration() - Date.now() / 1000
            } seconds`
          );
          var url = `${APIEndPoint}/api/field/0/vector/${searchBy}?${qs.stringify(
            { value: searchTerm }
          )}`;
          // var bearer = "Bearer " + bearer_token;
          fetch(url, {
            method: "GET",
            // withCredentials: true,
            // credentials: "include",
            headers: {
              Authorization: theAccessToken.getJwtToken(),
              // "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
              // "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then(({ success, result: gottenUsers }) => {
              if (!!success && Array.isArray(gottenUsers)) {
                setUserSearchResult(gottenUsers);
              }
            })
            .catch((error) => {
              console.log(`error`, error);
            });
        });
      }
    }, 500);
    return () => clearTimeout(getData);
  }, [searchTerm]);
  return (
    <div className="lg:col-span-9 py-6 px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Organizations</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of Organization based on criteria selected
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => setOpen(true)}
          >
            Add new
          </button>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="sm:mt-2 2xl:mt-5">
          <div className="border-b border-gray-200">
            <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {theTabs.map((name, key) => {
                  const isActive = activeTab === key;
                  return (
                    <a
                      key={key}
                      href="/"
                      onClick={(event) => {
                        event.preventDefault();
                        setActiveTab(key);
                        setLoaded(false);
                      }}
                      className={classNames(
                        isActive
                          ? "border-pink-500 text-gray-900"
                          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                        "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                      )}
                      aria-current={isActive ? "page" : undefined}
                    >
                      {name}
                    </a>
                  );
                })}
              </nav>
            </div>
          </div>
        </div>
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 mt-3">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {
                [
                  <table
                    key={1}
                    className="min-w-full divide-y divide-gray-300"
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Organization Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Requested On
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {organizationInvites.map((currentOrganizationInvite) => (
                        <tr key={currentOrganizationInvite.organization}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {currentOrganizationInvite.organizationName}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {currentOrganizationInvite.createdOn}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <a
                              href="/"
                              onClick={(event) => {
                                event.preventDefault();
                              }}
                              className="text-indigo-600 hover:text-indigo-900 mr-5"
                            >
                              Reject
                            </a>
                            <a
                              href="/"
                              onClick={(event) => {
                                event.preventDefault();
                              }}
                              className="text-indigo-600 hover:text-indigo-900 ml-5"
                            >
                              Accept
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>,
                  <table
                    key={2}
                    className="min-w-full divide-y divide-gray-300"
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Organization Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Created On
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {ownOrganizations.map((currentOrganization) => (
                        <tr key={currentOrganization.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {currentOrganization.name}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {currentOrganization.createdOn}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <a
                              href="/"
                              onClick={(event) => {
                                event.preventDefault();
                                navigate(
                                  `/organization/${currentOrganization.id}`
                                );
                              }}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              Details
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>,
                  <table
                    key={3}
                    className="min-w-full divide-y divide-gray-300"
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Organization Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Member Since
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {activeMemberships.map(
                        (currentActiveOrganizationMembership) => (
                          <tr
                            key={
                              currentActiveOrganizationMembership.organization
                            }
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {
                                currentActiveOrganizationMembership.organizationName
                              }
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {currentActiveOrganizationMembership.createdOn}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <a
                                href="/"
                                onClick={(event) => {
                                  event.preventDefault();
                                  navigate(
                                    `/organization/${currentActiveOrganizationMembership.organization}`
                                  );
                                }}
                                className="text-indigo-600 hover:text-indigo-900"
                              >
                                Details
                              </a>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>,
                  <table
                    key={4}
                    className="min-w-full divide-y divide-gray-300"
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Organization Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Requested On
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {joinRequests.map((currentOrganizationJoinRequest) => (
                        <tr key={currentOrganizationJoinRequest.organization}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {currentOrganizationJoinRequest.organizationName}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {currentOrganizationJoinRequest.createdOn}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <a
                              href="/"
                              onClick={(event) => {
                                event.preventDefault();
                              }}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              Retract
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>,
                  <table
                    key={5}
                    className="min-w-full divide-y divide-gray-300"
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Organization Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Owner Name
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {allOrganizations.map((currentOrganization) => (
                        <tr key={currentOrganization.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {currentOrganization.name}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {`${currentOrganization.ownerTitle} ${currentOrganization.ownerFirstName} ${currentOrganization.ownerSurname}`}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <a
                              href="/"
                              onClick={(event) => {
                                event.preventDefault();
                                navigate(
                                  `/organization/${currentOrganization.id}`
                                );
                              }}
                              className="text-indigo-600 hover:text-indigo-900"
                            >
                              Details
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>,
                ][Number(activeTab)]
              }
              {!loaded ? (
                <LoadingScreen
                  loading={`Fetching ${tabExplanations[Number(activeTab)]}`}
                  message={`Please wait, ${
                    tabExplanations[Number(activeTab)]
                  } are being fetched.`}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <form className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-indigo-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              New Organization
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-indigo-700 text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={() => setOpen(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-indigo-300">
                              Create an organization on this platform.
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              {!selectedUser ? (
                                <div>
                                  <div className="mt-1">
                                    <Listbox
                                      value={createOrganizationFor}
                                      onChange={(value) => {
                                        setSearchTerm("");
                                        setCreateOrganizationFor(value);
                                      }}
                                    >
                                      {({ open }) => (
                                        <>
                                          <Listbox.Label className="block text-sm font-medium text-gray-700">
                                            Create Organization For
                                          </Listbox.Label>
                                          <div className="relative mt-1">
                                            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                              <span className="block truncate">
                                                {
                                                  possibleOrganizationCreationVectors[
                                                    createOrganizationFor
                                                  ]
                                                }
                                              </span>
                                              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronUpDownIcon
                                                  className="h-5 w-5 text-gray-400"
                                                  aria-hidden="true"
                                                />
                                              </span>
                                            </Listbox.Button>

                                            <Transition
                                              show={open}
                                              as={Fragment}
                                              leave="transition ease-in duration-100"
                                              leaveFrom="opacity-100"
                                              leaveTo="opacity-0"
                                            >
                                              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                {possibleOrganizationCreationVectors.map(
                                                  (person, key) => (
                                                    <Listbox.Option
                                                      key={key}
                                                      className={({ active }) =>
                                                        classNames(
                                                          active
                                                            ? "text-white bg-indigo-600"
                                                            : "text-gray-900",
                                                          "relative cursor-default select-none py-2 pl-3 pr-9"
                                                        )
                                                      }
                                                      value={key}
                                                    >
                                                      {({
                                                        selected,
                                                        active,
                                                      }) => (
                                                        <>
                                                          <span
                                                            className={classNames(
                                                              selected
                                                                ? "font-semibold"
                                                                : "font-normal",
                                                              "block truncate"
                                                            )}
                                                          >
                                                            {person}
                                                          </span>

                                                          {selected ? (
                                                            <span
                                                              className={classNames(
                                                                active
                                                                  ? "text-white"
                                                                  : "text-indigo-600",
                                                                "absolute inset-y-0 right-0 flex items-center pr-4"
                                                              )}
                                                            >
                                                              <CheckIcon
                                                                className="h-5 w-5"
                                                                aria-hidden="true"
                                                              />
                                                            </span>
                                                          ) : null}
                                                        </>
                                                      )}
                                                    </Listbox.Option>
                                                  )
                                                )}
                                              </Listbox.Options>
                                            </Transition>
                                          </div>
                                        </>
                                      )}
                                    </Listbox>
                                  </div>
                                </div>
                              ) : null}
                              {createOrganizationFor ===
                              0 ? null : !!selectedUser ? (
                                <div
                                  className={
                                    "text-white bg-indigo-600 relative cursor-default select-none py-2 pl-3 pr-9"
                                  }
                                >
                                  <small>Create Organization For</small>
                                  <span
                                    className={"font-semibold block truncate"}
                                  >
                                    {`${selectedUser.name}`}
                                  </span>
                                  <span
                                    onClick={(event) => {
                                      event.preventDefault();
                                      setSelectedUser(null);
                                    }}
                                    className={
                                      "text-white absolute inset-y-0 right-0 flex items-center pr-4"
                                    }
                                  >
                                    <XMarkIcon
                                      className="h-6 w-6"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </div>
                              ) : (
                                <div>
                                  {!selectedUser ? (
                                    <div>
                                      <div className="mt-1">
                                        <Listbox
                                          value={searchBy}
                                          onChange={(value) => {
                                            setSearchTerm("");
                                            setSearchBy(value);
                                          }}
                                        >
                                          {({ open }) => (
                                            <>
                                              <Listbox.Label className="block text-sm font-medium text-gray-700">
                                                Search By
                                              </Listbox.Label>
                                              <div className="relative mt-1">
                                                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                                  <span className="block truncate">
                                                    {
                                                      possibleUserSearchVectors[
                                                        searchBy
                                                      ]
                                                    }
                                                  </span>
                                                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <ChevronUpDownIcon
                                                      className="h-5 w-5 text-gray-400"
                                                      aria-hidden="true"
                                                    />
                                                  </span>
                                                </Listbox.Button>

                                                <Transition
                                                  show={open}
                                                  as={Fragment}
                                                  leave="transition ease-in duration-100"
                                                  leaveFrom="opacity-100"
                                                  leaveTo="opacity-0"
                                                >
                                                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {possibleUserSearchVectors.map(
                                                      (person, key) => (
                                                        <Listbox.Option
                                                          key={key}
                                                          className={({
                                                            active,
                                                          }) =>
                                                            classNames(
                                                              active
                                                                ? "text-white bg-indigo-600"
                                                                : "text-gray-900",
                                                              "relative cursor-default select-none py-2 pl-3 pr-9"
                                                            )
                                                          }
                                                          value={key}
                                                        >
                                                          {({
                                                            selected,
                                                            active,
                                                          }) => (
                                                            <>
                                                              <span
                                                                className={classNames(
                                                                  selected
                                                                    ? "font-semibold"
                                                                    : "font-normal",
                                                                  "block truncate"
                                                                )}
                                                              >
                                                                {person}
                                                              </span>

                                                              {selected ? (
                                                                <span
                                                                  className={classNames(
                                                                    active
                                                                      ? "text-white"
                                                                      : "text-indigo-600",
                                                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                                                  )}
                                                                >
                                                                  <CheckIcon
                                                                    className="h-5 w-5"
                                                                    aria-hidden="true"
                                                                  />
                                                                </span>
                                                              ) : null}
                                                            </>
                                                          )}
                                                        </Listbox.Option>
                                                      )
                                                    )}
                                                  </Listbox.Options>
                                                </Transition>
                                              </div>
                                            </>
                                          )}
                                        </Listbox>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="mt-1">
                                    <label
                                      htmlFor="new-admin-search-term-input"
                                      className="block text-sm font-medium text-gray-900"
                                    >
                                      Search a regular User
                                    </label>
                                    <div className="relative mt-1">
                                      <input
                                        value={searchTerm}
                                        onChange={(event) => {
                                          event.preventDefault();
                                          setSearchTerm(event.target.value);
                                        }}
                                        onFocus={(event) => {
                                          event.preventDefault();
                                          setShowUserSearchResult(true);
                                        }}
                                        onBlur={(event) => {
                                          event.preventDefault();
                                          // setShowUserSearchResult(false);
                                        }}
                                        type="text"
                                        placeholder={`By ${possibleUserSearchVectors[searchBy]}`}
                                        name="new-admin-search-term-input"
                                        id="new-admin-search-term-input"
                                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                      />
                                      {!!showUserSearchResult ? (
                                        <ul className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                          {userSearchResult.map(
                                            (currentUser, key) => {
                                              return !!currentUser.id &&
                                                !!currentUser.name ? (
                                                <li
                                                  key={key}
                                                  className={
                                                    "hover:bg-indigo-600 text-gray-900 relative cursor-default select-none py-2 pl-3 pr-9"
                                                  }
                                                  onClick={(event) => {
                                                    event.preventDefault();
                                                    setSelectedUser(
                                                      currentUser
                                                    );
                                                    setShowUserSearchResult(
                                                      false
                                                    );
                                                  }}
                                                >
                                                  <span
                                                    className={
                                                      "font-normal block truncate"
                                                    }
                                                  >
                                                    {`${currentUser.name}`}
                                                  </span>
                                                </li>
                                              ) : null;
                                            }
                                          )}
                                        </ul>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div>
                                <label
                                  htmlFor="organization-name-input"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Organization Name
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    value={organizationName}
                                    onChange={(event) => {
                                      event.preventDefault();
                                      setOrganizationName(event.target.value);
                                    }}
                                    name="organization-name-input"
                                    id="organization-name-input"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div>
                              {/* <div>
                                <label
                                  htmlFor="organization-slug-input"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Organization Slug
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="organization-slug-input"
                                    id="organization-slug-input"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                  />
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          onClick={(event) => {
                            event.preventDefault();
                            setOrganizationCreationLoading(true);
                            if (
                              organizationName === undefined ||
                              organizationName === null ||
                              (isNaN(organizationName) &&
                                !(
                                  typeof organizationName.trim === "function"
                                )) ||
                              `${organizationName}`.trim().length < 4
                            ) {
                              setOrganizationCreationLoading(false);
                              return alert(
                                `Organization name must contain at least four(4) characters`
                              );
                            }
                            if (
                              !selectedUser &&
                              !(createOrganizationFor === 0)
                            ) {
                              setOrganizationCreationLoading(false);
                              return alert(
                                `Please provide a user before proceeding to create organization`
                              );
                            }
                            theCurrentUser.getSession(
                              (error, cognitoUserSession) => {
                                console.log(`error`, error);
                                if (!!error) {
                                  setOrganizationCreationLoading(false);
                                  return alert(error.message);
                                }
                                const theAccessToken =
                                  cognitoUserSession.getAccessToken();
                                // console.log(`theAccessToken.getJwtToken()`, theAccessToken.getJwtToken());
                                console.log(
                                  `It is ${
                                    theAccessToken.getExpiration() >
                                    Date.now() / 1000
                                  } that session is still active for ${
                                    theAccessToken.getExpiration() -
                                    Date.now() / 1000
                                  } seconds`
                                );
                                const whoToCreateOrganizationFor =
                                  !!selectedUser ? selectedUser.id : me.id;
                                // setNewUserTitle
                                fetch(
                                  `${APIEndPoint}/api/user/${
                                    !!selectedUser ? selectedUser.id : me.id
                                  }/organization`,
                                  {
                                    method: "POST",
                                    body: JSON.stringify({
                                      name: organizationName,
                                    }),
                                    // withCredentials: true,
                                    // credentials: "include",
                                    headers: {
                                      Authorization:
                                        theAccessToken.getJwtToken(),
                                      // "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
                                      "Content-Type": "application/json",
                                    },
                                  }
                                )
                                  .then((response) => response.json())
                                  .then(
                                    ({
                                      success,
                                      organization,
                                      message = `An unexpected error occured`,
                                    }) => {
                                      if (!!success && !!organization) {
                                        setOrganizations([
                                          organizationInvites,
                                          whoToCreateOrganizationFor === me.id
                                            ? [
                                                organization,
                                                ...ownOrganizations,
                                              ]
                                            : ownOrganizations,
                                          activeMemberships,
                                          joinRequests,
                                          !(
                                            whoToCreateOrganizationFor === me.id
                                          )
                                            ? [
                                                organization,
                                                ...allOrganizations,
                                              ]
                                            : allOrganizations,
                                        ]);
                                        setOpen(false);
                                      }
                                      alert(message);
                                    }
                                  )
                                  .catch((error) => {
                                    console.log(`error`, error);
                                    alert(error.message);
                                  })
                                  .then(() => {
                                    setOrganizationCreationLoading(false);
                                  });
                              }
                            );
                          }}
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Create Organization
                        </button>
                      </div>
                      {organizationCreationLoading ? (
                        <LoadingScreen
                          loading="Attempting to create organization"
                          message="Validating each of the fields provided please wait!"
                        />
                      ) : null}
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
