import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import ForcedPasswordChange from "./ForcedPasswordChange";
import Login from "./Login";
import Register from "./Register";
export default function Unauthenticated({
  userPool,
  coreRefresh,
  setCoreRefresh,
}) {
  const [newPasswordRequiredData, setNewPasswordRequiredData] = useState(null);
  // cognitoUser,
  // userAttributes,
  console.log(`Unauthenticated userPool`, userPool);
  console.log(
    `Unauthenticated setNewPasswordRequiredData`,
    setNewPasswordRequiredData
  );
  return !!newPasswordRequiredData &&
    !!newPasswordRequiredData.cognitoUser &&
    !!newPasswordRequiredData.userAttributes &&
    Array.isArray(newPasswordRequiredData.requiredAttributes) ? (
    <ForcedPasswordChange
      {...{ 
        coreRefresh,
        setCoreRefresh,newPasswordRequiredData, setNewPasswordRequiredData }}
    />
  ) : (
    <Routes>
      <Route
        path="/"
        element={
          <Login
            {...{
              coreRefresh,
              setCoreRefresh,
              userPool,
              setNewPasswordRequiredData,
            }}
          />
        }
      />
      <Route
        path="/login"
        element={
          <Login
            {...{
              coreRefresh,
              setCoreRefresh,
              userPool,
              setNewPasswordRequiredData,
            }}
          />
        }
      />
      <Route
        path="/register"
        element={
          <Register
            {...{
              coreRefresh,
              setCoreRefresh,
              userPool,
              setNewPasswordRequiredData,
            }}
          />
        }
      />
      {/* <Route path="about" element={<AboutPage />} /> */}
    </Routes>
  );
}
